<template>
  <div class="w-screen h-screen absolute top-0 left-0 flex justify-center items-center overflow-hidden pointer-events-none opacity-5">
    <div class="w-[200vw] h-[200vh] flex justify-center items-center rotate-[30deg]">
      <div class="" v-for="j in 100">
        <div v-for="i in 100" class="text-[10px] p-1 w-[100px] break-all font-mono">{{ watertext }}</div>
      </div>
    </div>
  </div>
</template>

<script setup>

  import { toRefs } from "vue";

  const props = defineProps(["watertext"]);

  const { watertext } = toRefs(props);
</script>
